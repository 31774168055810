footer {
  margin-top: 250px;
}

.footer-icon {
  height: 40px;
  width: 40px;
  margin: 25px;
}

.footer-icon-fb {
  height: 35px;
  width: 35px;
  margin: 25px;
}

.copywrite {
  color: rgb(255, 255, 255);
}