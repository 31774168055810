@import "../../../index.scss";

.projects {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.project-img {
  min-width: 200px;
  min-height: 200%;
  max-height: 350px;
  max-width: 350px;
}

.project-card {
  width: 375px;
  animation: pop 500ms ease-in-out forwards;
  transform-origin: center;
}

.project-card:hover {
  margin: -10px;
  width: 385px;
}