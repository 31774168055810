@import "../../../index.scss";

.links {
  // background-color: #fbfbf9;
  color: $yellow;
  cursor: pointer;
  font-family: Sanchez, serif;
  font-size: 20px;
  font-weight: bold;
  width: 100%;
}

.links:hover {
  text-decoration: none;
  color: $dark-yellow;
}
