.bio-pics {
  height: 200px;
  width: 200px;
}

.commits {
  animation: delay 1s, fadeIn 1s 1s, slide 1s 1s;
}

.githubHist {
  box-shadow: none;
}

section {
  display: block;
  letter-spacing: 0.05em;
  font-size: 18px;
}

.bg-about {
  background-color: rgb(22, 103, 195, 0.66);
  margin-top: 50px;
}

.picDiv {
  margin: -30px -5px 0px 0px;
}

.profilePic {
  transform: rotate(-5deg);
}

@keyframes slide {
  0% {
    transform: translateX(-25px) translateY(-25px) rotate(-3deg);
  }
  100% {
    transform: translateX(0px) translateY(0px);
  }
}
