@import "../../index.scss";

.biopic {
  animation: delay 0.5s, pop 1s 0.5s;
  background: transparent;
  float: left;
  margin-left: -50px;
  padding: 0px;
}

.intro {
  animation: delay 1s, pop 1s 1s;
  // border-radius: 5px;
  float: right;
  font-size: 30px;
  font-weight: 300;
  margin: 75px -45px 0px 0px;
  text-align: left;
}

.learnMore {
  animation: delay 1.5s, pop 1s 1.5s;
  // border-radius: 5px;
  cursor: pointer;
  display: inline-block;
  font-size: 30px;
  margin: 25px auto -50px auto;
  text-align: center;
}
