.funFacts {
  animation: delay 1.25s, fadeIn 1s 1.25s, slide 1s 1.25s ease;
  height: 300px;
}

.header {
  height: 20%;
}

.facts {
  height: 80%;
}

.directionals {
  font-size: 36px;
}

.fact {
  padding: 25px 50px 0px 50px;
  font-size: 24px;
}
