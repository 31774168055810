@import "../../index.scss";

nav {
  margin-bottom: 100px;
  background-color: rgba($bg-h, .75);
}

.name {
  font-family: "Sanchez", serif;
  letter-spacing: 0.05em;
  padding: 5px;
}

.name:hover {
  text-decoration: none;
}

.divider {
  background-color: $red;
  display: inline;
  padding: 1px;
}

.first {
  color: $green;
  display: inline;
  font-size: 24px;
  margin-right: -5px;
  padding: 0px;
}

.last {
  color: $yellow;
  display: inline;
  font-size: 24px;
  letter-spacing: 0.10em;
  margin-left: -5px;
}

.b-icon {
  color: rgb(22, 103, 195, 0.95);
}

.p-icon {
  color: rgb(113, 26, 144, 0.95);
}

.c-icon {
  color: rgb(227, 31, 13, 0.95);
}

.menu-btn {
  width: 65px;
  height: 65px;
}

.homeBtn {
  color: rgb(22, 103, 195, 0.95);
  border: solid;
  border-color: rgb(22, 103, 195, 0.95);
}

.homeBtn:hover {
  color: white;
  border-color: rgb(22, 103, 195, 0.95);
  background-color: rgb(22, 103, 195, 0.95);
}

.portfolioBtn {
  color: rgb(113, 26, 144, 0.95);
  border: solid;
  border-color: rgb(113, 26, 144, 0.95);
}

.portfolioBtn:hover {
  color: white;
  border-color: rgb(113, 26, 144, 0.95);
  background-color: rgb(113, 26, 144, 0.95);
}

.contactBtn {
  color: rgb(227, 31, 13, 0.95);
  border: solid;
  border-color: rgb(227, 31, 13, 0.95);
}

.contactBtn:hover {
  color: white;
  border-color: rgb(227, 31, 13, 0.95);
  background-color: rgb(227, 31, 13, 0.95);
}