$bg-h: #1d2021;
$bg: #282828;
$bg1: #3c3836;
$bg2: #504945;
$bg3: #665c54;
$dark-red: #cc241d;
$red: #fb4934;
$dark-green: #98971a;
$green: #b8bb26;
$dark-yellow: #d79921;
$yellow: #fabd2f;
$dark-blue: #458588;
$blue: #83a598;
$dark-purple: #b16286;
$purple: #d3869b;
$dark-aqua: #689d6a;
$aqua: #8ec07c;
$dark-orange: #d65d0e;
$orange: #fe8019;
$gray: #928374;

body {
  font-family: "Roboto Condensed", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

main {
  margin: 0px auto 25px auto;
}

header {
  background-color: $bg-h;
  box-shadow: 10px 7px $dark-yellow;
  color: white;
  font-family: Sanchez, serif;
  margin: -50px auto -50px auto;
}

footer {
  text-align: center;
  bottom: 0;
}

img {
  box-shadow: 10px 7px $dark-purple;
}

.page {
  background-color: $bg1;
}

.bg-main {
  background-color: $bg2;
  box-shadow: 13px 10px $bg-h;
  margin: 50px auto 0px auto;
  padding: 0px;
}

.infoBox {
  background-color: $bg-h;
  box-shadow: 10px 7px $dark-green;
  color: white;
}

.linkBox {
  background-color: $bg-h;
  box-shadow: 10px 7px $dark-blue;
  color: $yellow;
  font-family: Sanchez;
}

.string {
  color: $green;
  font-family: Sanchez;
}

.value {
  color: $purple;
  font-family: Sanchez;
}

.statement {
  color: $red;
  font-family: Sanchez;
}

.header1 {
  color: $dark-blue;
  font-family: Sanchez;
  font-size: 36px;
  margin-top: 15px;
  opacity: 25%;
  text-align: center;
}

.element {
  color: $dark-blue;
  font-family: Sanchez;
  opacity: 25%;
}
