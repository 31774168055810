.contact-link {
  color: rgb(25, 25, 25, 0.95);
  font-size: 18px;
}

.dropdown-icon {
  height: 35px;
  width: 35px;
  margin-right: 5px;
}

.dropdown-icon-fb {
  height: 31px;
  width: 31px;
  margin-right: 5px;
}
