@keyframes delay {
  0% { opacity: 0; }
  100% { opacity: 0; }
}

.drop {
  animation: dropDown 250ms ease-in-out forwards;
  transform-origin: top center;
}

.header {
  animation: dropDown .3s ease-in-out forwards;
  // border-radius: 5px;
  display: inline-block;
  padding: 20px;
  transform-origin: top center;
}

@keyframes dropDown {
  0% {
    transform: scaleY(0);
  }
  100% {
    transform: scaleY(1);
  }
}

.ease-right {
  animation-name: ease-right;
  animation-timing-function: ease;
  animation-duration: 1s;
}

@keyframes ease-right {
  0% {
    transform: translateX(-50px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.ease-left {
  animation-name: ease-left;
  animation-timing-function: ease;
  animation-duration: 1s;
}

@keyframes ease-left {
  0% {
    transform: translateX(50px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.fadeIn {
  animation: fadeIn 2s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes pop {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.typedText {
  display: inline-block;
  font-size: 60px;
  font-family: "Sanchez", serif;
  overflow: hidden;
  border-right: solid;
  white-space: nowrap;
  margin: 0px;
  animation: delay .3s, typing .5s .3s steps(120, end), blink-caret 0.75s .3s step-end infinite;
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 95%;
  }
}

@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: white;
  }
}